
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
body{
    padding-top: 60px;
}

.full {
    width: 100%;
    }
    .gap {
    height: 30px;
    width: 100%;
    clear: both;
    display: block;
    }
    .footer {
    background: #EDEFF1;
    height: auto;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #CCCCCC;
    border-top: 1px solid #DDDDDD;
    }
    .footer p {
    margin: 0;
    }
    .footer img {
    max-width: 100%;
    }
    .footer h3 {
    border-bottom: 1px solid #BAC1C8;
    color: #54697E;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    padding: 40px 0 10px;
    text-transform: uppercase;
    }
    .footer ul {
    font-size: 13px;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 15px;
    color: #7F8C8D;
    }
    .footer ul li a {
    padding: 0 0 5px 0;
    display: block;
    }
    .footer a {
    color: #78828D
    }
    .supportLi h4 {
    font-size: 20px;
    font-weight: lighter;
    line-height: normal;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    }
    .newsletter-box input#appendedInputButton {
    background: #FFFFFF;
    display: inline-block;
    float: left;
    height: 30px;
    clear: both;
    width: 100%;
    }
    .newsletter-box .btn {
    border: medium none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    height: 40px;
    padding: 0;
    width: 100%;
    color: #fff;
    }
    .newsletter-box {
    overflow: hidden;
    }
    .bg-gray {
    background-image: -moz-linear-gradient(center bottom, #BBBBBB 0%, #F0F0F0 100%);
    box-shadow: 0 1px 0 #B4B3B3;
    }
    .social li {
    background: none repeat scroll 0 0 #B5B5B5;
    border: 2px solid #B5B5B5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    float: left;
    height: 36px;
    line-height: 36px;
    margin: 0 8px 0 0;
    padding: 0;
    text-align: center;
    width: 36px;
    transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    }
    .social li:hover {
    transform: scale(1.15) rotate(360deg);
    -webkit-transform: scale(1.1) rotate(360deg);
    -moz-transform: scale(1.1) rotate(360deg);
    -ms-transform: scale(1.1) rotate(360deg);
    -o-transform: scale(1.1) rotate(360deg);
    }
    .social li a {
    color: #EDEFF1;
    }
    .social li:hover {
    border: 2px solid #2c3e50;
    background: #2c3e50;
    }
    .social li a i {
    font-size: 16px;
    margin: 0 0 0 5px;
    color: #EDEFF1 !important;
    }
    .footer-bottom {
    background: #E3E3E3;
    border-top: 1px solid #DDDDDD;
    padding-top: 10px;
    padding-bottom: 10px;
    }
    .footer-bottom p.pull-left {
    padding-top: 6px;
    }
    .payments {
    font-size: 1.5em;	
    }

    /* to break down col-xs */
    .visible-xxs {
    display: none!important;
    }
    @media (max-width: 525px) {
    .col-xxs-12 {
        float: none;
        width: 100%;
        
        position: relative; left: 0; right: 0;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .hidden-xxs {
        display: none!important;
    }
    .visible-xxs {
        display: block!important;
    }
    }

.card{
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 2px;
    color: rgba(0,0,0,.84);
    background: #fff;
    box-shadow: 0 8px 17px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
}

.anuncio-container{
    .anuncio{
        margin-top:1em;
    }
    .anuncio:hover{
        cursor: pointer;
        background: rgba(206, 202, 202, 0.671);
    }
}

.anuncio-actions{
    text-align: right;
    right: 1em;
    top: 10px;
}

.anuncio-avatar{
    max-width: 100%;
}

.anuncio{
    //width: 600px;
    //max-width: 100%;
    padding: 1em;
    position: relative;    
}

.parrafo{
    font-size: 14px;
    white-space: pre-wrap;
}

.zoom{
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.zoom:hover{
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

abbr:hover{
    cursor: pointer;
}

.row.row-grid [class*="col-"] + [class*="col-"] {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

@media (min-width: 1200px) {
    .row.row-grid [class*="col-lg-"] + [class*="col-lg-"] {
        margin-top: 0;
    }
}
@media (min-width: 992px) {
    .row.row-grid [class*="col-md-"] + [class*="col-md-"] {
        margin-top: 0;
    }
}
@media (min-width: 768px) {
    .row.row-grid [class*="col-sm-"] + [class*="col-sm-"] {
        margin-top: 0;
    }
}

//GOOGLE ADS
/*extra small - max width */
@media (max-width: 767px) {

    /*.hidden-xs { display: none!important; } */
    .mrec-xs { width:300px; height:250px; }
    .leaderboard-xs { width:728px; height:90px; }
    .mobile-xs { width:320px; height:50px; }
    .skyscraper-xs { width:120px; height:600px; }
    .banner-xs { width:468px; height:60px; }
    }
    
    /* small */
    @media (min-width: 768px) and (max-width: 991px) {
    
    /*.hidden-sm { display: none!important; } */
    .mrec-sm { width:300px; height:250px; }
    .leaderboard-sm { width:728px; height:90px; }
    .mobile-sm { width:320px; height:50px; }
    .skyscraper-sm { width:120px; height:600px; }
    .banner-sm { width:468px; height:60px; }
    }
    
    /* medium */
    @media (min-width: 992px) and (max-width: 1199px) {
    
    /*.hidden-md { display: none!important; } */
    .mrec-md { width:300px; height:250px; }
    .leaderboard-md { width:728px; height:90px; }
    .mobile-md { width:320px; height:50px; }
    .skyscraper-md { width:120px; height:600px; }
    .banner-md { width:468px; height:60px; }
    }
    
    /* large */
    @media (min-width: 1200px) {
    
    /*.hidden-lg { display: none!important; } */
    .mrec-lg { width:300px; height:250px; }
    .leaderboard-lg { width:728px; height:90px; }
    .mobile-lg { width:320px; height:50px; }
    .skyscraper-lg { width:120px; height:600px; }
    .banner-lg { width:468px; height:60px; }
    }

    .checked_true{
        -webkit-transition: all 2s ease-in-out;
        -moz-transition:    all 2s ease-in-out;
        -o-transition:      all 2s ease-in-out;
        transition:         all 2s ease-in-out;
    }

    @-webkit-keyframes checked_true {
        100%{
            -moz-box-shadow:    0px 0px 20px 10px #28b62c;
            -webkit-box-shadow: 0px 0px 20px 10px #28b62c;
            box-shadow:         0px 0px 20px 10px #28b62c;
        }
    }
    
    @keyframes checked_true {
        100%{
            -moz-box-shadow:    0px 0px 20px 10px #28b62c;
            -webkit-box-shadow: 0px 0px 20px 10px #28b62c;
            box-shadow:         0px 0px 20px 10px #28b62c;
        }
    }

    .checked_false{
        -moz-box-shadow:    0px 0px 20px 10px #ff4136;
        -webkit-box-shadow: 0px 0px 20px 10px #ff4136;
        box-shadow:         0px 0px 20px 10px #ff4136;
        -webkit-transition: all 2s ease-in-out;
        -moz-transition:    all 2s ease-in-out;
        -o-transition:      all 2s ease-in-out;
        transition:         all 2s ease-in-out;
    }
